@media (min-width: 1300px) {
	.container {
	  max-width: 1280px;
	}
  }
  @media (min-width: 1200px) {
	.container {
		max-width: 93% !important;
	}
}
  /*@media (min-width: 992px) {
	  .navbar-toggler {
		  display: none;
	  }
	  .btn.close-btn {
		  display: none;
	  }
	  
  }*/
  @media (min-width: 991px) {
	
	.trers-grid-image {
	  img {
		max-width: 150px;
	  }
	}
  }
  
  @media (max-width: 1199px) {
	footer{
        .footer-links {
            display: block;
        }
    }
	.free-shipping-section {
	  ul {
		flex-flow: wrap;
		li {
		  margin-bottom: 15px;
		}
	  }
	}
	body {
	  padding-top: 47px;
	}
  
	.header {
	  .flex-container {
		.header-row-one {
		  display: block;
		  .account-section {
			position: absolute;
			top: 0;
			width: 100%;
			border-bottom: 1px solid #00000012;
			left: 0;
			z-index: 99;
			background: #fff;
			.account {
			  justify-content: flex-end;
    		 margin-top: 10px;
			}
		  }
		}
	  }
	}
  }
  @media (max-width: 991px) {

	.product-details-wrapper {
		.slider-nav {
			bottom:6px;
			
		}
		.details-wrapper {
			
			.product-all-details {
				.row {
					.col-left {
						flex: auto;
					}
				}
				.product-addtional-details{
					padding-bottom:15px;
					&:before {
						width:100%;
					}
				}
			}
		}
	}
	.banner-content {
		.content {
		  h2, h1 {
			font-size: 30px;
		  }
		}
	  }
	/**Product**/
	.product-box-inner {
	  .product-image {
		max-width: 150px;
	  }
	  .product-detail {
		.price-wrapper {
		  position: inherit;
		  transform: none;
		}
		.add-to {
		  position: inherit;
		}
	  }
	  .verified {
		top: 10px;
	  }
	}
  
	.best-products {
	  .row-fluid {
		display: block;
		.tyers-grid {
		  justify-content: space-between;
		  li {
			display: inline-block;
			margin: 15px 0px 15px 0px;
			text-align: center;
		  }
		}
	  }
	  .column {
		&.single-image {
		  margin-top: 15px;
		}
	  }
	}
  }
  
  @media (max-width: 767px) {
	.mob-filter{
		
		z-index: 200;
		background-color: $bg-color;
		width: 100%;
		top: 0;
    	bottom:0;
		left:0;
    	position:fixed;
    	overflow-y:scroll;
    	overflow-x:hidden;
	}

	.filter-button{
		display: block !important;
		position: fixed;
    	bottom: 4%;
    	left: 45%;
   		width: 50%;
    	height: 8%;
		border-radius: 50px;
		border: 2px solid $primary;
		font-size: 18px;
		font-weight: 600;
		-webkit-box-shadow: 0px 0px 33px -10px rgba(0,0,0,0.59);
		-moz-box-shadow: 0px 0px 33px -10px rgba(0,0,0,0.59);
		box-shadow: 0px 0px 33px -10px rgba(0,0,0,0.59);
	}


	.details-wrapper {
		padding-right: 0px !important;
    	padding-left: 0px !important;
    	margin-right: 0px !important;
    	margin-left: 0px !important;
		.slider-nav {
			bottom:6px;
			max-width: 240px;
			min-width: 230px;
		}
	}
	.banner-content {
		.content {
		  h2, h1 {
			font-size: 20px;
		  }
		}
	  }
	.aside-group {
	  display: block;
	}
  
	/**Title**/
	.content-title {
	  .title-box {
		.title {
		  &:after {
			display: none;
		  }
		}
	  }
	}
  
	.header {
	  .flex-container {
		display: block;
		.logo {
		  text-align: center;
		  padding: 15px;
		  margin:0px auto;
		}
		.header-row-one {
			.search-bar-group {
				
				.search-bar {
					margin-top:10px;
					margin-right: -4rem;
					width:300% !important;
					
				}
			}
		}
	  }
	}
  
	.content-1-section {
	  position: static;
	  border-radius: 0px;
	}
	.homepage-banner-slider {
	  .owl-theme {
		.owl-dots {
		  bottom: 0;
		}
	  }
	}
  
	.best-products {
	  .column {
		.tyers-grid {
		  li {
			display: block;
			text-align: center;
		  }
		}
	  }
	}
  }
  .mobile-filter{
	display: none;
  }
  .desktop-filter{
	display: block;
  }
 
  @media (min-width: 640px) {
	.cle-bannerdisplay{
		width: 117%;
		margin-top: 10%;
		text-align-last: center;
        //margin-left: 79px;
	}
	.cle-banner{
		display: flex ;
		margin-left: 20px;
        width: 98%;
	}
	.col-md-4 {
		flex: 0 0 41.333333% !important;
	}
	.linenraj-banner{
		display: flex ;
        width: 100%;
	}
	.cle-deskprodtitle{
		font-size: 17px;
		font-weight: 500;
	}
	.cle-deskproprice{
		color: #938d8d;
    font-weight: 500;
	}
	.cle-deskprodimg{
		object-fit: contain;
		height: 90%;
		width: 100%;
	}
  .cle-categorymenu{
	display: grid;
    grid-template-columns: repeat(3,1fr);
  }
  .cle-homenav{
	margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .cle-homenav1{
	margin-right: 1px !important;
    margin-left: 1px !important;
  }
  .cle-homenav2{
	margin-right: -2px !important;
    margin-left: 9px !important;
  }
  
  .cle-menu1{
	width: 170% !important;
	transform: translate3d(60px,8px,0) !important;
  }
  .cle-menu2{
	width: 190% !important;
	transform: translate3d(140px,8px,0) !important;
  }
  .cle-menurow{
	width: 99% !important;
  }
  .cle-eachmenu{
	width: 380px!important;
  }
  .cle-headericon{
	font-size: 13px;
  }
  .cle-card{
	border: none;
  }
  .cle-header{
	border-bottom: none !important;
  }
  .cle-bannerimage{
	display: flex;
	justify-content: end;
  }
  
  .cle-bannertitle{
	font-weight: 400 !important;
	text-transform: none;
	font-size: 56px;
	line-height: 5.3125rem !important;
	letter-spacing: -.029rem;
	color: #000a12; text-align: center;
  }
  .cle-bannertext{
	font-weight: 400;
	display: flex;
	justify-content: center;
	text-align: center;
	font-size: 1rem; color: #757272;
  }
  .cle-bannerbutton{
	margin-right: auto;
	margin-left: auto; display: flex;
	justify-content: center;background-color: white;
    padding: 9px 21px;
    margin-top: 27px;
    border: black 1px solid;
    font-weight: 500;
  }
  .cle-bannerbutton:hover{
	margin-right: auto;
	margin-left: auto; display: flex;
	justify-content: center;background-color:black;
	color: white!important;
    padding: 9px 21px;
    margin-top: 27px;
    border: black 1px solid;
    font-weight: 500;
  }
  .desk-cleprod{
	display: grid; 
	margin-left: 14px;
	grid-template-columns: repeat(4,1fr); 
	max-width: 100%;
  }
  .cle-homeprod{
	margin-right: 20px;
	margin-bottom: 20px;
  }
  .cle-homeprodtitle{
	font-weight: 400 !important;text-shadow:none;
	text-transform: none;
	font-size: 2.6rem !important;
	line-height: 3.125rem !important;
	letter-spacing: -.029rem;
	color: #000a12;width: 77%;
  }
  }
  @media (max-width: 640px) {

	.special-checkout{display: flex;
		justify-content: center;
		width: 109px;}
		
	.cle-homeprodtitle{
		font-weight: 400 !important;text-shadow:none;
		text-transform: none;
		font-size: 32px !important;
		line-height: 3.125rem !important;
		letter-spacing: -.029rem;
		color: #000a12;
	  }
	.cle-deskprodimg{
		object-fit: contain;
		height: 90%;
		width: 100%;
	}
	.cle-homeprod{
		height: fit-content;
		width: 150px;
		margin-right: 33px;
		margin-bottom: 20px;
	  }
	.desk-cleprod{
		display: grid; 
		grid-template-columns: repeat(2,1fr); 
	  }
	.cle-bannerimage{
		display: flex;
		justify-content: center;
	  }
	.cle-bannerbutton:hover{
		margin-right: auto;
		margin-left: auto; display: flex;
		justify-content: center;background-color: white;
		padding: 9px 21px;
		margin-top: 19px;
		margin-bottom: 20px;
		border: black 1px solid;
		font-weight: 500;
	  }
	  .cle-bannerbutton{
		margin-right: auto;
		margin-left: auto; display: flex;
		justify-content: center;background-color:black;
		padding: 9px 21px;
		margin-top: 19px;
		margin-bottom: 20px;
		color: white;
		border: black 1px solid;
		font-weight: 500;
	  }
	.cle-bannertext{
		margin-left: 10px;
		font-weight: 400;
		display: flex;
		justify-content: center;
		text-align: center;
		font-size: 1rem; color: #757272;
	  }
	.cle-bannertitle{
		font-weight: 400 !important;
		text-transform: none;
		font-size: 27px;
		line-height: 2.3125rem !important;
		letter-spacing: 0.029rem;
		color: #000a12; text-align: center;
	  }
	.cle-bannerdisplay{	
    width: 95%;
    margin-top: 10%;
	}
	.collection-wrapper {
	  .col-grid-box {
		padding: 0px 10px 0px 10px;
	  }
	}
	
	footer{
        .footer-links {
            display: grid !important;
        }
		.footer-col{
			justify-content: center !important;
			padding: 5px;
			margin:5px;
			min-width:136%;
		}
    }

	.banner-content {
	  .content {
		h2, h1 {
		  font-size: 18px;
		}
	  }
	}
	.header {
	  .flex-container {
		.header-row-one {
		  .search-bar {
			margin-left: 0px;
		  }
		  .account-section{
			position: fixed !important;
			z-index: 2 !important;
			padding-right:0px;
			.account{
				margin-right: 10px !important;
			}
			.dropdown-toggle{
				font-size:10px !important;
			}
			.fa-user{
				font-size:10px !important
			}
		  }
		}
	  }
	}


	.product-box-inner {
	  display: block;
	  padding: 10px 0px 10px 0;
	  .img-wrapper {
		padding-bottom: 10px;
		.product-image {
		  max-width: 100%;
		  width: 100%;
		}
	  }
  
	  .product-detail {
		padding-left: 0px;
	  }
	  .verified {
		top: 10px;
		transform: none;
	  }
	}
  
	/*Pagenation**/
	.product-pagination {
	  .row {
		display: block;
		> div {
		  margin-bottom: 10px;
		  text-align: center;
		  p {
			margin-bottom: 0px;
		  }
		  .pagination {
			justify-content: center;
		  }
		}
	  }
	}
	.mobile-filter{
		display: block !important;
	  }
	.desktop-filter{
		display : none !important;
	}
	.mob-categories{
		
		opacity: 1;
		padding:10px;
		font-family: 'proxima-regular','sans-serif'!important;
		position: fixed;
		background-color: white;
		z-index: 100;
		width: 100%;
		height: 100%;
		border-right: 50px solid rgba(0, 0, 0, 0.5);
		overflow-y: auto;
		top:0;
		left:0;
		background-clip: padding-box;
		
		
	}
	.touch-out{
		 background-color:transparent;
		 position: fixed;
		 z-index: 999;
		 width: 60px;
		 right: 0px;
		 height:300%;
		 top:0px;
		 overflow:hidden;
		
	}
	// body{
	// 	position: fixed !important;
	// }

  }
  /*** Range **/
  
  @media (max-width: 991px) and (min-width: 640px) {
	.product-name {
	  font-size: 18px;
	}
	.price {
	  font-size: 18px;
	}
  
	.product-price {
	  .tax-includes {
		font-size: 13px;
	  }
	}
	.verified {
	  font-size: 13px;
	}
	.add-to {
	  .btn-solid {
		font-size: 15px;
	  }
	}
  }


  .imgclass{
	position: absolute;
    top: 35px;
    left: 70px;
  }

  .title_message {
	display: block;
  }

  .title_message3 {
	display: block;
  }

  .title_message4 {
	display: block;
  }

  .title_message1 {
	display: none;
  }

  .title_message2 {
	display: none;
  }

  @media screen and (max-width: 600px) {
	.title_message {
	  display: none;
	}
	.title_message4 {
		display: none;
	  }

	.title_message3 {
		display: none;
	  }

	.title_message1 {
		display: block;
	  }

	  .title_message2 {
		display: none;
	  }
	  .bcrumb_resp{
		margin-top: 40px;
	  }
	 .nav-item{
		height: 150%;
		width: 30% !important;
	  }
  
	}
@media only screen and (min-device-width: 720px) and (max-device-width: 1280px) {
	  .title_message3 {
		display: none;
	  }
	.title_message2 {
		display: block;
	  }
	  .title_message4 {
		display: none;
	  }
}
.cle-homeprodtitle{
	width: 100%;
	margin-top: 10px;
    margin-bottom: 42px;
}



@media only screen and (min-device-width: 720px) and (max-device-width: 1300px) {
	.avonHome{
		display: none!important;
		}
		
		.avonHome1{
		display: none!important;
		}
		
		.avonHome2{
		display: flex!important;
		}

	
		
		.avonHome3{
		display: none!important;
		}
        
		.avonHome5{
			display: flex!important;	
		}

		.navBar2{
			display: none!important;
			}

		.header2{
			display: flex!important;
			}
			
			.header1{
			display: none!important;
			}

			.loginheader{
				margin-right: 19%;
			}
		
}

@media only screen and (min-device-width: 720px) and (max-device-width: 1280px) {
		
		.header2{
		display: none!important;
		}
		
		.header1{
		display: flex!important;
		}

		.loginheader{
			margin-right: 8%;
		}

		.avonHome5{
			display: flex!important;	
			right: -20% !important;
		}

		.navBar2{
			display: none!important;
			}
		
}

@media only screen and (min-device-width: 1535px) {
	.avonHome{
		display: none!important;
	}

	.avonHome1{
		display: flex!important;
	}
	.avonHome2{
		display: none!important;
	}
	.navBar2{
		display: none!important;
		}
	.avonHome3{
		display: none!important;
	}

	.avonHome5{
		display: none!important;
		right: -20% !important;
		}

}

@media only screen and (min-device-width: 1366px)  {
.avonHome{
	display: none!important;
}

.avonHome1{
	display: none!important;
}

.avonHome2{
	display: flex!important;
}
.avonHome3{
	display: none!important;
}

	.header2{
		display: none!important;
		}

		
		.avonHome5{
			display: flex!important;	
			right: -20% !important;
		}

		.navBar2{
			display: none!important;
			}

}

@media only screen and (min-device-width: 1420px) {
.avonHome{
display: none!important;
}

.avonHome1{
display: none!important;
}

.avonHome2{
display: none!important;
}

.navBar2{
	display: none!important;
	}

.avonHome3{
display: flex!important;
}

.avonHome5{
	display: none!important;
	right: -20% !important;
	}

}


@media only screen and (min-device-width: 1900px) {
	.avonHome{
		display: none!important;
		}
		
		.avonHome1{
		display: flex!important;
		}
		
		.avonHome2{
		display: none!important;
		}

		.navBar2{
			display: none!important;
			}
		
		.avonHome3{
		display: none!important;
		}

		.avonHome5{
			display: none!important;
			right: -20% !important;
			}
}

